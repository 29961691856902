import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import Logo from "../../images/logo.svg";

const FooterBottomContainer = styled.div`
  background: ${({ theme }) => theme.lighterGray};
  svg {
    max-width: 100%;
  }
`;
const FooterBottomWrapper = styled.div`
  padding: 4rem 0;
  display: grid;
  place-items: center;
  gap: 1.5rem;
  & > p,
  & > div {
    display: flex;
    align-items: center;
    line-height: 1;
    color: ${({ theme }) => theme.darkGray};
    & > * {
      margin-left: 2rem;
      line-height: 0;
    }
  }
`;

const FooterBottom = () => {
  return (
    <FooterBottomContainer>
      <div className="container">
        <FooterBottomWrapper>
          <Logo />
          <p>Minden jog fenntartva {new Date().getFullYear()}.</p>
          <div>
            Készítette:{" "}
            <a
              href="https://itechline.hu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../../images/itechlogo.png" alt="ITECHLINE" />
            </a>
          </div>
        </FooterBottomWrapper>
      </div>
    </FooterBottomContainer>
  );
};

export default FooterBottom;
