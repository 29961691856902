import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import Button from "../General/Button.component";
import PplIkon from "../../images/ppl.svg";
import TelIkon from "../../images/tel.svg";

const CallBackStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  transform: scale(0);
  transform-origin: top;
  transition: all 0.5s ease;
  background: rgba(53, 57, 71, 0.9);
  z-index: 10001;
  padding: 8rem;
  place-items: center;
  text-align: center;
  color: white;
  ${(props) =>
    props.opened &&
    css`
      transform: scale(1);
    `}
  .close {
    font-size: 6rem;
    font-weight: 100;
    margin-bottom: 6rem;
    cursor: pointer;
  }
  .lead {
    font-size: 3.6rem;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 6rem;
    &:after {
      content: "";
      background-color: white;
      width: 8rem;
      height: 5px;
      position: relative;
      display: block;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .desc {
    margin-bottom: 3rem;
  }
  .acc-wrapper {
    display: inline;
    input {
      width: fit-content;
      margin-right: 2rem;
    }
    a {
      text-decoration: underline !important;
    }
    margin: 3rem 0;
  }
  form {
    display: flex;
    flex-direction: column;
    div {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  input {
    background: transparent;
    border-left: none;
    border-right: none;
    color: white;
    max-width: 50%;
    margin: 0 auto;
    padding: 2rem 2rem 2rem 5rem;
    &::-webkit-input-placeholder {
      color: white;
    }

    &:-moz-placeholder {
      color: white;
    }

    &::placeholder {
      color: white;
    }
  }
  @media (max-width: 992px) {
    padding: 4rem 0;
    .close {
      font-size: 4rem;
    }
    .lead {
      font-size: 2.8rem;
    }
    input {
      max-width: 100%;
    }
  }
`;

const CallBack = (props) => {
  return <></>;
  return (
    <CallBackStyle opened={props.openCallBack}>
      <div className="container">
        <div className="close" onClick={() => props.setOpenCallBack(false)}>
          X
        </div>
        <div className="lead">
          Visszahívást
          <br />
          kérek
        </div>
        <div className="desc">
          Adja meg elérhetőségeit és mi a lehető
          <br />
          leghamarabb visszahívjuk Önt.
        </div>
        <form>
          <div>
            <PplIkon />
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Hogyan szólíthatunk"
            />
          </div>
          <div>
            <TelIkon />
            <input
              type="tel"
              name="tel"
              id="tel"
              placeholder="+36 __ ___ ____"
            />
          </div>
          <div className="acc-wrapper">
            <input type="checkbox" name="acceptance" id="acceptance" required />
            <label htmlFor="acceptance">
              Elfogadom az{" "}
              <Link to="/adatvedelem">adatvédelmi nyilatkozatot</Link> és a{" "}
              <Link to="/felhasznaloi-feltetelek">
                felhasználói feltételeket
              </Link>
              .
            </label>
          </div>
          <Button styleO={{ bg: "darkBlue" }}>Elküldöm</Button>
        </form>
      </div>
    </CallBackStyle>
  );
};

export default CallBack;
