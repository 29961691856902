import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import CallBack from "../CallBack/CallBack.component";
import Footer from "./Footer.component";
import TopMenu from "./TopMenu.component";
import TopRow from "./TopRow.component";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
  html{
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  body {
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    @media (max-width: 992px){
      margin-top: 8rem;
    }
  }
  button{
    outline: none;
    border: none;
  }
  a{
    color: currentColor !important;
    text-decoration: none !important;
  }
  form{
    width: 100%;
  }
  input, textarea{
    outline: none;
    border: 1px solid #e2e2e2;
    padding: 2rem;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
  }
  .container {
    max-width: 1170px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    .container {
      max-width: 100%;
    }
  }
  .MuiOutlinedInput-root.MuiInputBase-root{
    .MuiSelect-outlined{
    min-width: 30rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }}
  .MuiMenuItem-root{
    font-size: 1.5rem !important;
  }
  .MuiFormControl-root{
    font-family: 'Open Sans',sans-serif !important;

    max-width: 100%;
    .MuiFormLabel-root{
      color: ${(props) => props.theme.darkGray};
      font-size: 1.5rem;
      &.Mui-focused, &.MuiFormLabel-filled{
        font-size: 1.1rem;
      }
    }
    .MuiInputBase-input{
      color: ${(props) => props.theme.darkGray};
      font-size: 1.4rem;
    }
  }
`;

const theme = {
  darkBlue: "#037ad4",
  lightBlue: "#70b5ea",
  lightGray: "#dcdcdc",
  darkGray: "#7c7a7a",
  orange: "#ffaa35",
  lighterGray: "#f5f5f5",
  red: "#ee0f0f",
  purple: "#b3bcea",
  darkOrange: "#ff8135",
  transparent: "#fff", //for transparent color bug
};

export default function Layout(props) {
  const [openCallBack, setOpenCallBack] = useState(false);
  React.useEffect(() => {
    setOpenCallBack(props?.openCallBack);
  }, [props?.openCallBack]);
  React.useEffect(() => {
    if (openCallBack === false && props.setOpenCallBack)
      props.setOpenCallBack(false);
  }, [openCallBack]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <GlobalStyle />
      <main style={{ overflowX: "hidden" }}>
        <CallBack
          openCallBack={openCallBack}
          setOpenCallBack={setOpenCallBack}
        />
        <TopRow setOpenCallBack={setOpenCallBack} />
        <TopMenu setOpenCallBack={setOpenCallBack} />
        {props.children}
        <Footer />
      </main>
    </ThemeProvider>
  );
}
