import React from "react";
import styled, { css } from "styled-components";

const getButtonColor = (style) => {
  let styleData;
  switch (style) {
    case "white":
      styleData = {
        bg: "white",
        color: "darkBlue",
        border: {
          size: "2px",
          type: "solid",
          color: "darkBlue",
        },
      };
      break;
    case "lightBlue":
      styleData = {
        bg: "lightBlue",
        color: "white",
        border: {
          size: "2px",
          type: "solid",
          color: "white",
        },
      };
      break;
    case "orange":
      styleData = {
        bg: "orange",
        color: "white",
        border: {
          size: "2px",
          type: "solid",
          color: "white",
        },
      };
      break;
    case "transparent":
      styleData = {
        bg: "transparent",
        color: "black",
        border: {
          size: "2px",
          type: "solid",
          color: "black",
        },
      };
      break;
    default:
      styleData = {
        border: {
          size: "1px",
          type: "solid",
          color: "lightBlue",
        },
        bg: "darkBlue",
        color: "#fff",
      };
      break;
  }
  return styleData;
};

const ButtonStyle = styled.button`
  background-color: ${({ theme, buttonColor }) =>
    theme[buttonColor.bg] || buttonColor.bg};
  color: ${({ theme, buttonColor }) =>
    theme[buttonColor.color] || buttonColor.color};
  border: ${({ theme, buttonColor }) =>
    `${buttonColor.border.size} ${buttonColor.border.type} ${
      theme[buttonColor.border.color] || buttonColor.border.color
    }`};
  border-radius: 5px;
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s ease;
  font-weight: 900;
  letter-spacing: 0.15rem;
  white-space: nowrap;
  ${({ isShadowed }) =>
    isShadowed
      ? css`
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
        `
      : ""}
  &:hover {
    color: ${({ theme, buttonColor }) =>
      theme[buttonColor.bg] || buttonColor.bg};
    background-color: ${({ theme, buttonColor }) =>
      theme[buttonColor.color] || buttonColor.color};
    border-color: ${({ theme, buttonColor }) =>
      theme[buttonColor.bg] || buttonColor.bg};
  }
  @media (max-width: 992px) {
    ${(props) =>
      props.large &&
      css`
        padding: 1.5rem 7rem;
      `}
  }
`;

const Button = ({ callback, styleO, children, large }) => {
  const buttonColor = getButtonColor(styleO.bg);

  return (
    <div>
      <ButtonStyle
        onClick={callback}
        isShadowed={styleO.shadow}
        buttonColor={buttonColor}
        large={large}
      >
        {children}
      </ButtonStyle>
    </div>
  );
};

export default Button;
