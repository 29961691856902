import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { footerInfo } from "../../statics/footerInfo.static";
import FooterBottom from "./FooterBottom.component";

const FooterContainer = styled.div`
  padding: 4rem 0;
`;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FooterItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p  {
    font-size: 1.4rem;
    font-weight: 900;
    margin-bottom: 2rem;
  }
  a {
    color: ${({ theme }) => theme.darkGray} !important;
    margin-bottom: 0.5rem;
  }
`;
const Footer = () => {
  const fooInf = footerInfo;
  return (
    <>
      <FooterContainer>
        <div className="container">
          <FooterWrapper>
            {fooInf.map((item, i) => (
              <FooterItemsWrapper key={`fooinf${i}`}>
                <p>{item.title}</p>
                {item.subs.map((sub, i) =>
                  sub.outer ? (
                    <a
                      href={sub.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={`subinf${i}`}
                    >
                      {sub.title}
                    </a>
                  ) : (
                    <Link key={`subl${i}`} to={sub.href}>
                      {sub.title}
                    </Link>
                  )
                )}
              </FooterItemsWrapper>
            ))}
          </FooterWrapper>
        </div>
      </FooterContainer>
      <FooterBottom />
    </>
  );
};

export default Footer;
