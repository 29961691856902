import WindowIcon from "../../images/ablak-ikon.svg";
import DoorIcon from "../../images/ajto-ikon.svg";
import CallbackIcon from "../../images/visszahivas-ikon.svg";
import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import Logo from "../../images/logo.svg";

const ContainerStyle = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
`;

const ButtonContainerStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem 0;
  & svg {
    margin-right: 0.5rem;
  }
  & > div {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 900;
  }
  & > div:last-of-type {
    margin-right: 0;
  }
`;

const TopRow = (props) => {
  return (
    <div className="container">
      <ContainerStyle>
        <Link to="/">
          <Logo />
        </Link>
        <ButtonContainerStyle>
          <div onClick={() => navigate("/ablak-tervezo")}>
            <WindowIcon />
            Ablak
            <br />
            tervező
          </div>
          <div onClick={() => navigate("/kapcsolat")}>
            <DoorIcon />
            Ajtó
            <br />
            tervező
          </div>
          {/* <div onClick={() => props.setOpenCallBack(true)}>
            <CallbackIcon />
            Visszahívást
            <br />
            kérek
          </div> */}
        </ButtonContainerStyle>
      </ContainerStyle>
    </div>
  );
};

export default TopRow;
