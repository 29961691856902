import { Link } from "gatsby";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import Logo from "../../images/logo.svg";
import WindowIcon from "../../images/ablak-ikon.svg";
import DoorIcon from "../../images/ajto-ikon.svg";
import CallbackIcon from "../../images/visszahivas-ikon.svg";
import { navigate } from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const NavStyle = styled.nav`
  background-color: ${({ theme }) => theme.darkBlue};
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.3);
  @media (max-width: 992px) {
    display: none;
  }
`;

const NavUlStyle = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  & li {
    color: white;
    text-transform: uppercase;
    position: relative;
    padding: 2rem 0;
  }
  & li a:after {
    content: "";
    position: absolute;
    background: white;
    width: 0;
    height: 5px;
    bottom: 0;
    transition: all 0.5s ease;
    left: 0;
  }
  & li a:hover:after,
  & .active:after {
    width: 100%;
  }
`;

const MobileNav = styled.div`
  display: none;
  padding: 2rem;
  position: relative;
  background: ${({ theme }) => theme.lighterGray};
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.3);
  div {
    max-width: 65%;
    &:first-of-type {
      margin-top: 0.5rem;
    }
  }
  height: 8rem;
  width: 100%;
  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
`;

const MobileNavContent = styled.div`
  display: flex;
  transition: all 0.5s ease;
  transform: translateX(-100%);
  height: calc(100vh - 8rem);
  overflow-y: scroll;
  position: fixed;
  top: 8rem;
  width: 100%;
  background: white;
  left: 0;
  z-index: 998;
  padding: 2rem;
  font-weight: 300;
  ul {
    width: 100%;
    &.dropdown-menu li {
      border-bottom: none;
    }
  }
  li {
    padding: 1.5rem 0;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.lightGray};
    font-size: 1.4rem;
    text-transform: uppercase;
    position: relative;
    .active {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -2rem;
        background: ${({ theme }) => theme["orange"]};
        height: 100%;
        width: 5px;
      }
      font-weight: 900;
    }
    &.dropdown {
      li {
        border-bottom: none;
      }
      &:after {
        border-style: solid;
        border-width: 0.2rem 0.2rem 0 0;
        border-color: ${(props) => props.theme.darkGray};
        content: "";
        display: inline-block;
        height: 0.6rem;
        right: 1rem;
        position: absolute;
        top: 50%;
        transform: rotate(135deg) translateX(-50%);
        transition: all 0.5s ease;
        vertical-align: top;
        width: 0.6rem;
      }
      &.active:after {
        transform: rotate(-45deg) translateX(-50%);
        right: 0.5rem;
      }
    }
    &.extra {
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: 900;
      border-bottom: none;
      svg {
        margin-right: 1rem;
      }
    }
  }
`;

const MobileNavOpener = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  padding: 1rem;
  flex-direction: column;
  gap: 7px;
  background: ${({ theme }) => theme.darkBlue};
  z-index: 1001;
  div {
    width: 30px;
    height: 3px;
    background: white;
    max-width: 100%;
    position: relative;
    transition: all 0.5s ease;
  }
  @media (max-width: 992px) {
    display: flex;
  }
  ${({ opened }) =>
    opened &&
    css`
      div:nth-child(2) {
        background: transparent;
      }
      div:nth-child(1) {
        transform: rotate(45deg);
        top: 1rem;
      }
      div:nth-child(3) {
        transform: rotate(-45deg);
        top: -1rem;
      }
    `}
`;

const TopMenu = (props) => {
  const [nav, setNav] = useState(false);
  const [openWebshopDd, setOpenWebshopDd] = useState(false);
  return (
    <>
      <MobileNav>
        <div>
          <Link to="/">
            <Logo style={{ maxWidth: "100%" }} />
          </Link>
        </div>

        <MobileNavOpener onClick={() => setNav(!nav)} opened={nav}>
          <div className="opener"></div>
          <div className="opener"></div>
          <div className="opener"></div>
        </MobileNavOpener>
      </MobileNav>
      <MobileNavContent
        style={
          nav
            ? { transform: "translateX(0)" }
            : { transform: "translateX(-100%)" }
        }
      >
        <ul>
          <li>
            <Link to="/" activeClassName="active" onClick={() => setNav(false)}>
              Kezdőlap
            </Link>
          </li>
          <li>
            <Link
              to="/bemutatkozas"
              activeClassName="active"
              partiallyActive={true}
              onClick={() => setNav(false)}
            >
              Bemutatkozás
            </Link>
          </li>
          <li>
            <Link
              to="/szolgaltatasaink"
              activeClassName="active"
              partiallyActive={true}
              onClick={() => setNav(false)}
            >
              Szolgáltatásaink
            </Link>
          </li>
          <li>
            <Link
              to="/referenciaink"
              activeClassName="active"
              partiallyActive={true}
              onClick={() => setNav(false)}
            >
              Referenciáink
            </Link>
          </li>
          {/*  <li onClick={() => setNav(false)}>
            <Link to="/karrier" activeClassName="active">
              Karrier
            </Link>
          </li> */}
          <li
            className={`dropdown${openWebshopDd ? " active" : ""}`}
            onClick={() => setOpenWebshopDd(!openWebshopDd)}
          >
            <span className={`${openWebshopDd && "active"}`}>Webshop</span>
          </li>
          {openWebshopDd && (
            <ul className="dropdown-menu">
              <li>Ablakok</li>
              <li>Ajtók</li>
              <li>Párkányok</li>
            </ul>
          )}
          <li>
            <Link
              to="/kapcsolat"
              activeClassName="active"
              partiallyActive={true}
              onClick={() => setNav(false)}
            >
              Kapcsolat
            </Link>
          </li>
          <li
            className="extra"
            onClick={() => {
              setNav(false);
              navigate("/ablak-tervezo");
            }}
          >
            <WindowIcon />
            Ablak tervező
          </li>
          <li className="extra">
            <DoorIcon />
            Ajtó tervező
          </li>
          {/* <li
            className="extra"
            onClick={() => {
              setNav(false);
              setOpenWebshopDd(false);
              props.setOpenCallBack(true);
            }}
          >
            <CallbackIcon />
            Visszahívást kérek
          </li> */}
        </ul>
      </MobileNavContent>
      <NavStyle>
        <div className="container">
          <NavUlStyle>
            <li>
              <Link to="/" activeClassName="active">
                Kezdőlap
              </Link>
            </li>
            <li>
              <Link
                to="/bemutatkozas"
                activeClassName="active"
                partiallyActive={true}
              >
                Bemutatkozás
              </Link>
            </li>
            <li>
              <Link
                to="/szolgaltatasaink"
                activeClassName="active"
                partiallyActive={true}
              >
                Szolgáltatásaink
              </Link>
            </li>
            <li>
              <Link
                to="/referenciaink"
                activeClassName="active"
                partiallyActive={true}
              >
                Referenciáink
              </Link>
            </li>
            {/*
            <li>
              <Link to="/karrier" activeClassName="active">
                Karrier
              </Link>
            </li> */}
            <li>
              <AnchorLink
                  to="/#ajanlataink"
                  activeClassName="active"
                  partiallyActive={true}
              >
                Ajánlataink
              </AnchorLink>
            </li>
            <li>
              <Link
                to="/kapcsolat"
                activeClassName="active"
                partiallyActive={true}
              >
                Kapcsolat
              </Link>
            </li>
          </NavUlStyle>
        </div>
      </NavStyle>
    </>
  );
};

export default TopMenu;
