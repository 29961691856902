export const footerInfo = [
  {
    title: "Hasznos",
    subs: [
      {
        title: "Bemutatkozás",
        href: "/bemutatkozas",
        outer: false,
      },
      {
        title: "Kapcsolat",
        href: "/kapcsolat",
        outer: false,
      },
      {
        title: "ÁSZF",
        href: "/aszf",
        outer: false,
      },
      {
        title: "Adatvédelem",
        href: "/adatvedelem",
        outer: false,
      },
      /* {
        title: "Munkalehetőség",
        href: "/karrier",
        outer: false,
      }, */
    ],
  },
  {
    title: "Egyedi igények",
    subs: [
      {
        title: "Egyedi ablakok",
        href: "/ablak-tervezo",
        outer: false,
      },
      {
        title: "Egyedi ajtók",
        href: "/kapcsolat",
        outer: false,
      } /*
      {
        title: "Egyedi párkányok",
        href: "https://shop.debrecenablak.hu",
        outer: true,
      },
      {
        title: "Egyedi redőnyök",
        href: "https://shop.debrecenablak.hu",
        outer: true,
      }, */,
    ],
  },
  {
    title: "Szolgáltatások",
    subs: [
      {
        title: "Gyártás",
        href: "/szolgaltatasaink",
        outer: false,
      },
      {
        title: "Beépítés",
        href: "/szolgaltatasaink",
        outer: false,
      },
      {
        title: "Partnerkapcsolat",
        href: "/szolgaltatasaink",
        outer: false,
      },
    ],
  },
];
