export const indexPage = {
  title: "Főoldal | Debrecen ablak",
  url: "/",
  type: "",
  description: "",
  image: "",
};
export const aboutPage = {
  title: "Bemutatkozás | Debrecen ablak",
  url: "/bemutatkozas",
  type: "",
  description: "",
  image: "",
};
export const servicesPage = {
  title: "Szolgáltatásaink | Debrecen ablak",
  url: "/szolgaltatasaink",
  type: "",
  description: "",
  image: "",
};
export const referencesPage = {
  title: "Referenciáink | Debrecen ablak",
  url: "/referenciaink",
  type: "",
  description: "",
  image: "",
};
export const carrierPage = {
  title: "Karrier | Debrecen ablak",
  url: "/karrier",
  type: "",
  description: "",
  image: "",
};
export const contactPage = {
  title: "Kapcsolat | Debrecen ablak",
  url: "/kapcsolat",
  type: "",
  description: "",
  image: "",
};
export const windowPage = {
  title: "Ablak tervező | Debrecen ablak",
  url: "/ablak-tervezo",
  type: "",
  description: "",
  image: "",
};
